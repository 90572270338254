<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="编辑客户档案" class="margin-bottom margin-top-xs"></el-page-header>
		<div>
			<el-divider content-position="left">基本信息</el-divider>
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="100px" class="flex flex-wrap">
					<el-form-item label="姓名" prop="real_name" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.real_name" placeholder="请输入会员姓名"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="出生日期" prop="birthday">
						<div class="form-item-box">
							<el-date-picker v-model="form.birthday" type="date" placeholder="选择生日"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="单位电话">
						<div class="form-item-box">
							<el-input v-model="form.work_phone" placeholder="请输入单位电话"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="住宅电话">
						<div class="form-item-box">
							<el-input v-model="form.home_phone" placeholder="请输入住宅电话"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="手机号码" prop="phone" :rules="[rules.phone]">
						<div class="form-item-box">
							<el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="通讯地址">
						<div class="form-item-box">
							<el-input v-model="form.postal_address" placeholder="请输入通讯地址"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="职业单位">
						<div class="form-item-box">
							<el-input v-model="form.occupation" placeholder="请输入职业单位"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="个人喜好">
						<div class="form-item-box">
							<el-input v-model="form.hobby" placeholder="请输入个人喜好"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="兴趣爱好">
						<div class="form-item-box">
							<el-input v-model="form.interest" placeholder="请输入兴趣爱好"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="登记时间">
						<div class="form-item-box">
							<el-input v-model="form.created_at" readonly></el-input>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-divider content-position="left">详细资料</el-divider>
			<div class="form_box">
				<el-form :model="form" label-width="100px">
					<el-form-item label="类型及特点">
						<el-input v-model="form.type_characteristic" type="textarea" :autosize="{ minRows: 4}">
						</el-input>
					</el-form-item>
					<el-form-item label="常去会所习惯">
						<el-input v-model="form.habit" type="textarea" :autosize="{ minRows: 4}"></el-input>
					</el-form-item>
					<el-form-item label="消费记录">
						<el-table :data="form.product_item" style="width: 100%">
							<el-table-column type="index" width="50">
							</el-table-column>
							<el-table-column prop="date" label="日期" width="210">
								<template slot-scope="scope">
									<el-date-picker v-model="scope.row.date" type="date" placeholder="选择日期"
										format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
									</el-date-picker>
								</template>
							</el-table-column>
							<el-table-column prop="product_name" label="购买产品/服务">
								<template slot-scope="scope">
									<el-input v-model="scope.row.product_name" placeholder="请输入购买产品/服务"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="100">
								<template slot-scope="scope">
									<el-button size="mini" v-if="scope.row.id" @click="delRecord(scope.row)"
										type="danger">删除</el-button>
									<el-button size="mini" v-if="!scope.row.id" @click="saveRecord(scope.row)"
										type="primary">保存</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	export default {
		data() {
			return {
				rules,
				form: {
					product_item: []
				}
			};
		},
		computed: {
			submitForm() {
				return {
					id: this.form.id,
					real_name: this.form.real_name,
					birthday: this.form.birthday,
					work_phone: this.form.work_phone,
					home_phone: this.form.home_phone,
					phone: this.form.phone,
					postal_address: this.form.postal_address,
					occupation: this.form.occupation,
					hobby: this.form.hobby,
					interest: this.form.interest,
					habit: this.form.habit,
					type_characteristic: this.form.type_characteristic
				}
			}
		},
		mounted() {
			// 获取当前编辑的会员信息
			if (this.$route.params.id) {
				this.loadData()
			}
		},
		methods: {
			loadData(){
				this.$api.table.getCustomerById({
					id: this.$route.params.id
				}).then(res => {
					this.form = res.data;
					this.addEmptyRecord()
				});
			},
			delRecord(row) {
				this.$confirm('确定要删除吗？', '提示', {
					type: 'warning'
				}).then(() => {
					this.$api.table.delConsumeById({
						id: row.id
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('操作成功')
							this.reloadProductItems()
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			addEmptyRecord() {
				this.form.product_item.push({
					date: '',
					product_name: '',
					customer_id: this.form.id
				})
			},
			saveRecord(row) {
				console.log(row)
				if(!row.date){
					this.$message.warning('请选择日期')
					return;
				}
				if(!row.product_name){
					this.$message.warning('请输入购买产品/服务')
					return;
				}
				
				this.$api.table.saveConsume(row).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
						this.reloadProductItems()
					}
				})
			},
			reloadProductItems(){
				this.$api.table.getCustomerById({
					id: this.$route.params.id
				}).then(res => {
					this.form.product_item = res.data.product_item;
					this.addEmptyRecord()
				});
			},
			onSubmit() {
				console.log(this.form)
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.table.saveCustomer(this.submitForm).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.loadData()
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
</style>
